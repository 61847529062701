<app-header></app-header>
<div class="hero-section section pt-0">
    <div class="container pt-5">
 
    </div>
   
  </div>

  <div class="about-section section container">
    <div class="row">
      <div class="col-12 col-lg-12 mb-3 mb-lg-0">
        <p class="display-5 fw-normal">Simba Coach</p>
        <p style="text-align: justify;">
          We are a leading bus transport company in East Africa. The company has operated long-distance Public Service Buses covering Kenya, Uganda and South Sudan. We are dedicated to offering our customers the best experience on board and on the road. Our major regional destinations include Mombasa, Malindi, Lamu, Ukunda, Nairobi, Nakuru, Kisumu, Jinja, Kampala, Mbale and Juba.
          Besides passenger service, we also have parcel and courier services to all our destinations.
        </p>
       
      </div>
      
    </div>
  </div>
  <app-footer></app-footer>
  